import Header from '../components/Header';
import { Curve } from '../icons/Icons';
import { Link } from 'react-router-dom';

const NoPathFound = () => {
    return (
        <>
            <div className='absolute w-full top-0 left-0 z-10'>
                <Header />
            </div>

            <div className='relative'>
                <div
                    style={{
                        background: 'linear-gradient(to right, #EFF2FE 0%, white 100%)',
                    }}
                    className='min-h-screen w-full pt-28 pb-16 px-4 sm:px-12 flex items-center justify-center'
                >
                    <div className='max-w-5xl mx-auto text-center'>
                        <div className='mt-12 mb-8'>
                            <h1 className='text-7xl sm:text-8xl font-bold text-[#222222] mb-6'>404</h1>
                            <h2 className='text-3xl sm:text-4xl font-semibold text-[#333333] mb-4'>Page Not Found</h2>
                            <p className='text-xl sm:text-2xl text-[#555555] max-w-2xl mx-auto mb-8'>
                                The page you're looking for doesn't exist or has been moved to another location.
                            </p>
                            <div className='mt-10'>
                                <Link
                                    to='/'
                                    className='bg-[#3366FF] hover:bg-[#2952cc] text-white font-semibold py-3 px-8 rounded-lg transition-all duration-300'
                                >
                                    Return to Homepage
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute bottom-0 left-0 w-full overflow-hidden mb-[-1px]'>
                    <Curve />
                </div>
            </div>
        </>
    );
};

export default NoPathFound;
