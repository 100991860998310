import InfoWrapper from './InfoWrapper';
import CheckMarkCard from './CheckMarkCard';

export const CheckMarks = () => {
    const cardTexts = [
        'Zaboravljanje rokova plaćanja',
        'Pretrpanost papirima',
        'Slučajno brisanje računa u e-mailu',
        'Komplikacije u podjeli troškova s obitelji ili cimerima',
        'Upravljanje velikim brojem računa',
        'Gubitak računa',
        'Nedostatak uvida u potrošnju',
        'Usporeni proces plaćanja',
        'Kompleksnost više načina plaćanja',
        'Teško pronalaženje informacija o davno plaćenim računima.',
        'Nedostatak kontrole nad računima u pokretu',
        'Upravljanje računima za više lokacija',
    ];

    return (
        <div className='w-full py-6 flex flex-col items-center justify-center text-center text-black'>
            <InfoWrapper
                title='Problemi koje riješavamo'
                description='Uz Račun.hr zaboravite na na tipične probleme sa računima'
                titleColor='text-[#19191B]'
                descriptionColor='text-[#696871] font-circular'
                textAlign='center'
            />
            <div className='mb-12 md:mb-16 lg:mb-24' />

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 w-full max-w-6xl px-4 md:px-6 lg:px-8 pb-12'>
                {cardTexts.map((text, index) => (
                    <div key={index} className='flex justify-center w-full'>
                        <CheckMarkCard text={text} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckMarks;
