export const CustomReminder = ({ description, title, bgColor, bgCircle, titleColor, descriptionColor }) => {
    return (
        <div className={`w-[325px] h-[113px] bg-transparent flex-col p-2`}>
            <div className='flex flex-row gap-4'>
                <div>
                    <div
                        style={{ backgroundColor: bgColor }}
                        className={`w-[62px] h-[62px] rounded-full flex items-center justify-center `}
                    >
                        <div style={{ backgroundColor: bgCircle }} className={`w-[14px] h-[14px] rounded-full`}></div>
                    </div>
                </div>

                <div className='flex flex-col justify-center gap-1 space-y-2'>
                    <p className={`text-[24px]  text-left leading-[30.36px]  tracking-[-0.75px] font-roboto font-medium ${titleColor} `}>
                        {title}
                    </p>
                    <h1 className={`font-roboto font-thin text-[14px]  text-left tracking-[-0.44px] ${descriptionColor} opacity-50`}>
                        {description}
                    </h1>
                </div>
            </div>
        </div>
    );
};
export default CustomReminder;
