import { useEffect, useRef, useState } from 'react';
import Button from '../components/Button';
import ContactUsSection from '../components/ContactUsSection';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InfoWrapper from '../components/InfoWrapper';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Input } from '../components/Input';
import InputMessage from '../components/InputMessage';
import axios from 'axios';
import { CONTACT } from '../constants/api';

const Contact = () => {
    const imgRef = useRef(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
        general: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
            }));
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            first_name: '',
            last_name: '',
            email: '',
            subject: '',
            message: '',
            general: '',
        };

        if (!formData.first_name?.trim()) {
            newErrors.first_name = 'Ime je obavezno';
            valid = false;
        }

        if (!formData.last_name?.trim()) {
            newErrors.last_name = 'Prezime je obavezno';
            valid = false;
        }

        if (!formData.email?.trim()) {
            newErrors.email = 'Email adresa je obavezna';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Unesite važeću email adresu';
            valid = false;
        }

        if (!formData.subject?.trim()) {
            newErrors.subject = 'Subject je obavezan';
            valid = false;
        }

        if (!formData.message?.trim()) {
            newErrors.message = 'Poruka je obavezna';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const resetForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            subject: '',
            message: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        setErrors((prev) => ({ ...prev, general: '' }));

        try {
            const response = await axios.post(CONTACT, formData);

            if (response.data && response.data.status_code === 201) {
                setSuccessMessage('Vaša poruka je uspešno poslana! Odgovorićemo vam u najkraćem mogućem roku.');
                resetForm();
                setTimeout(() => {
                    setSuccessMessage('');
                }, 5000);
            }
        } catch (err) {
            console.error('Contact form submission error:', err);

            if (err.response && err.response.data) {
                const responseData = err.response.data;

                if (responseData.validation_errors && responseData.validation_errors.length > 0) {
                    const newErrors = { ...errors };

                    responseData.validation_errors.forEach((validationError) => {
                        if (newErrors.hasOwnProperty(validationError.field)) {
                            newErrors[validationError.field] = validationError.error;
                        }
                    });

                    setErrors(newErrors);
                } else if (responseData.error) {
                    setErrors((prev) => ({ ...prev, general: responseData.error }));
                }
            } else {
                setErrors((prev) => ({
                    ...prev,
                    general: 'Došlo je do greške prilikom slanja poruke. Molimo pokušajte ponovo.',
                }));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div>
            <div className='absolute w-full top-0 left-0 z-10'>
                <Header />
            </div>

            <ContactUsSection />

            <div className='flex flex-col justify-center items-center py-12'>
                <InfoWrapper
                    title='Odgovaramo u što kraćem roku na sve upite'
                    titleColor='text-[#000000]'
                    descriptionColor='text-[#000000]'
                    textAlign='start'
                >
                    {errors.general && (
                        <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4'>{errors.general}</div>
                    )}

                    {successMessage && (
                        <div className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4'>{successMessage}</div>
                    )}

                    <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
                        <div className='flex justify-center gap-4 flex-wrap sm:flex-nowrap'>
                            <Input
                                type='text'
                                placeholder='Vaše ime'
                                name='first_name'
                                value={formData.first_name}
                                onChange={handleChange}
                                error={errors.first_name}
                            />

                            <Input
                                type='text'
                                placeholder='Vaše prezime'
                                name='last_name'
                                value={formData.last_name}
                                onChange={handleChange}
                                error={errors.last_name}
                            />
                        </div>

                        <Input
                            type='email'
                            placeholder='Email adresa'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        <Input
                            type='text'
                            placeholder='Naslov'
                            name='subject'
                            value={formData.subject}
                            onChange={handleChange}
                            error={errors.subject}
                        />

                        <InputMessage
                            placeholder='Vaša poruka'
                            name='message'
                            value={formData.message}
                            onChange={handleChange}
                            error={errors.message}
                        />

                        <div className='h-[71px] w-full mt-2'>
                            <Button type='submit' disabled={isSubmitting}>
                                {isSubmitting ? 'Slanje u toku...' : 'Pošalji'}
                            </Button>
                        </div>
                    </form>
                </InfoWrapper>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
