import CheckMarks from '../components/CheckMarks';
import FunctionalitiesCard from '../components/FunctionalitiesCard';
import Header from '../components/Header';
import HeaderSectionPages from '../components/HeaderSectionPages';
import NewsLetter from '../components/NewsLetter';

const AboutUs = () => {
    return (
        <>
            <div className='absolute w-full top-0 left-0 z-10'>
                <Header />
            </div>

            <HeaderSectionPages
                title='O nama'
                description='Naša misija je unaprijediti vašu svakodnevicu kroz inovaciju, sigurnost i vrhunski korisnički servis.'
            />

            <div className='flex flex-col justify-center gap-10 md:gap-16 lg:gap-20 py-10 md:py-16 lg:py-20 px-4 md:px-6'>
                <div className='flex flex-col lg:flex-row gap-6 lg:gap-8 justify-center items-center'>
                    <div className='w-full lg:w-[550px] text-4xl sm:text-5xl md:text-6xl leading-tight md:leading-tight lg:leading-[81px] text-center lg:text-left'>
                        Naša misija je omogućiti jednostavno i efikasno upravljanje računima na jednom mjestu.
                    </div>

                    <div className='w-full lg:w-[515px] text-base sm:text-lg md:text-xl lg:text-[21px] leading-relaxed md:leading-relaxed lg:leading-[39px]'>
                        Račun.hr je inovativna platforma koja stoji iza tima iskusnih stručnjaka s dugogodišnjim iskustvom u fintech
                        industriji. Naš cilj je pružiti korisnicima jednostavno, sigurno i efikasno rješenje za upravljanje plaćanjem svih
                        njihovih računa. Kroz našu aplikaciju omogućavamo brz i jednostavan način praćenja, plaćanja i organiziranja računa,
                        čineći vaš svakodnevni život lakšim i pristupačnijim. Iskustvo našeg tima u kombinaciji s najnovijim tehnološkim
                        rješenjima omogućuje vam potpunu kontrolu nad plaćanjima, bilo gdje i bilo kada, sve putem mobilnog uređaja.
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row justify-center gap-4 w-full'>
                    <div className='w-full sm:w-auto mb-4 sm:mb-0 flex justify-center'>
                        <img
                            src='./images/AboutUsImage1.jpg'
                            alt='image1'
                            className='object-contain rounded-lg'
                            width={350}
                            height={515}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                aspectRatio: '350/515',
                            }}
                        />
                    </div>

                    <div className='w-full sm:w-auto flex justify-center'>
                        <img
                            src='./images/AboutUsImage2.jpeg'
                            alt='image2'
                            className='object-cover rounded-md'
                            width={730}
                            height={515}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                aspectRatio: '730/515',
                            }}
                        />
                    </div>
                </div>
            </div>

            <CheckMarks />
            <div id='functionalities'>
                <FunctionalitiesCard />
            </div>
            <NewsLetter />
        </>
    );
};

export default AboutUs;
