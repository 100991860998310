export const Reminder = ({ description, title, icon: Icon, bgColor, bgReminder, titleColor, descriptionColor }) => {
    return (
        <div className={`w-[305px] max-w-[305px] h-[113px] rounded-[10px] ${bgReminder} shadow-lg shadow-[#1C1B2033] flex-col p-2`}>
            <div className='flex flex-row gap-4 pl-4'>
                <div className='pt-4'>
                    <div className={`w-[31px] h-[31px] ${bgColor}  opacity-80 rounded-full flex items-center justify-center`}>
                        {Icon && <Icon className='inline-block w-[12px] h-[13.71px]' />}
                    </div>
                </div>

                <div className='flex flex-col justify-center gap-1 pt-4'>
                    <h1 className={`font-roboto font-thin text-[14px] leading-[16.41px] tracking-[-0.44px] ${descriptionColor} opacity-50`}>
                        {description}
                    </h1>
                    <p className={`text-[21px] leading-[24.61px]  tracking-[-0.66px] font-roboto font-medium ${titleColor} opacity-90 `}>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    );
};
