import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from './Button';
import InfoWrapper from './InfoWrapper';
import Footer from './Footer';
import { Curve3 } from '../icons/Icons';
import { useEffect, useRef } from 'react';
import { Input } from './Input';
import { useNavigate } from 'react-router-dom';
import { REGISTER } from '../constants/api';
import { useFormValidation } from '../hooks/useFormValidation';

export const NewsLetter = () => {
    const imgRef = useRef(null);
    const navigate = useNavigate();

    const initialFormData = {
        first_name: '',
        last_name: '',
        email: '',
    };

    const onSuccess = () => {
        navigate('/success', { replace: true });
        window.scrollTo(0, 0);
    };

    const { formData, errors, isSubmitting, handleChange, handleSubmit } = useFormValidation(initialFormData, REGISTER, onSuccess);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div className='relative w-full pt-6 flex flex-col items-center justify-center text-center bg-[#19191B] text-white'>
            <div className='absolute top-0 left-0 w-full overflow-hidden mt-[-1px] z-[2]'>
                <Curve3 />
            </div>
            <div
                ref={imgRef}
                className='absolute xl:top-[24px] md:top-[14px] top-[7px] left-0 overflow-hidden transition-transform duration-1000'
            >
                <img src='./images/l1-cta-oval.png' alt='ova2' data-aos='fade-right' />
            </div>
            <div className='pt-10 relative z-30'>
                <InfoWrapper
                    title='Newsletter'
                    description='Predstavljamo vam Racun.hr, inovativnu aplikaciju koja vam omogućava lako upravljanje i plaćanje računa – sve na jednom mestu! Zaboravite na kašnjenja i stres zbog rokova.
                                Pridružite se danas i preuzmite kontrolu nad svojim računima!'
                    titleColor='text-[#FFFFFF]'
                    descriptionColor='text-[#FFFFFF]'
                    textAlign='center'
                >
                    {errors.general && (
                        <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center'>
                            {errors.general}
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
                        <div className='flex justify-center gap-4 flex-wrap sm:flex-nowrap'>
                            <Input
                                placeholder='Vaše Ime'
                                type='text'
                                name='first_name'
                                value={formData.first_name}
                                onChange={handleChange}
                                error={errors.first_name}
                            />
                            <Input
                                placeholder='Vaše Prezime'
                                type='text'
                                name='last_name'
                                value={formData.last_name}
                                onChange={handleChange}
                                error={errors.last_name}
                            />
                        </div>
                        <Input
                            placeholder='Email Adresa'
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                            required
                        />

                        <div className='h-[71px] w-full'>
                            <Button type='submit' disabled={isSubmitting}>
                                {isSubmitting ? 'Registracija u toku...' : 'Prijavi se'}
                            </Button>
                        </div>
                    </form>
                </InfoWrapper>
            </div>
            <Footer />
        </div>
    );
};

export default NewsLetter;
