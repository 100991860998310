import CustomReminder from './CustomReminder';
import InfoWrapper from './InfoWrapper';
import { OvalIcon } from '../icons/Icons';

export const FunctionalitiesCard = () => {
    const reminderData = [
        {
            title: 'Upravljanje računima',
            description: 'Pregledajte i upravljajte svim računima na jednom mjestu, uz mogućnost povezivanja s računima putem e-maila.',
            bgColor: '#e7e7f7',
            bgCircle: '#5454D4',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
        {
            title: 'Analiza i kontrola troškova',
            description: 'Pratite svoju potrošnju, dijelite troškove s obitelji ili cimerima i upravljajte računima za više lokacija.',
            bgColor: '#f5e6e6',
            bgCircle: '#F04037',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
        {
            title: 'Plaćanje i sigurnost',
            description: 'Plaćajte sigurno i jednostavno, uz podršku za više načina plaćanja i brzo plaćanje u nekoliko klikova.',
            bgColor: '#f9f3ea',
            bgCircle: '#FEDC5A',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
        {
            title: 'Arhiviranje i pretraga',
            description: 'Automatski pohranite račune, brzo pronađite stare račune i koristite napredne filtre za pretragu.',
            bgColor: '#ebf1e9',
            bgCircle: '#77BF41',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
        {
            title: 'Podsjetnici i praćenje',
            description: 'Primajte automatske podsjetnike za rokove i pratite status plaćanja kako biste uvijek bili u toku.',
            bgColor: '#e5e3ed',
            bgCircle: ' #413E65',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
        {
            title: 'Mobilna dostupnost i jednostavnost',
            description: 'Upravljajte računima putem mobilnog telefona, uz brzu registraciju i lako podešavanje profila.',
            bgColor: '#e7e7f7',
            bgCircle: '#5454D4',
            titleColor: 'text-black',
            descriptionColor: 'text-black',
        },
    ];
    return (
        <div className='w-full py-6 flex flex-col items-center justify-center text-center bg-[#F7F7FB] text-black'>
            <InfoWrapper
                title='Funkconalnosti Račun.hr'
                description='Šta sve nudi naša platforma.'
                titleColor='text-[#19191B] '
                descriptionColor='text-[#696871] font-circular'
                textAlign='center'
            />
            <div className='mb-12 md:mb-16 lg:mb-24' />
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-auto h-auto px-4 md:px-6 lg:px-8 pb-12'>
                {reminderData.map((reminderObject, index) => (
                    <CustomReminder
                        key={index}
                        icon={OvalIcon}
                        title={reminderObject.title}
                        description={reminderObject.description}
                        bgColor={reminderObject.bgColor}
                        bgCircle={reminderObject.bgCircle}
                        titleColor={reminderObject.titleColor}
                        descriptionColor={reminderObject.descriptionColor}
                    />
                ))}
            </div>
        </div>
    );
};
export default FunctionalitiesCard;
