import React from 'react';

const Button = ({ children, variant = 'primary', className = '' }) => {
    const variants = {
        primary: 'bg-[#F04037] border-[#C31A12] text-white primary-effect',
        secondary: 'bg-transparent border-white text-white secondary-effect',
    };

    return (
        <button className={`border w-full h-full hvr-bounce-to-left custom-button ${variants[variant]} ${className}`}>
            <span className='font-roboto font-medium text-[21px] leading-[25px] tracking-[-0.66px] text-center'>{children}</span>
        </button>
    );
};

export default Button;
