import { Curve } from '../icons/Icons';

const HeaderSectionPages = ({ description, title, align }) => {
    return (
        <div className='relative'>
            <div
                style={{
                    background: 'linear-gradient(to right, #EFF2FE 0%, white 100%)',
                }}
                className='min-h-[400px] w-full pt-28 pb-16 px-4 sm:px-12'
            >
                <div className='max-w-5xl mx-auto'>
                    <div className={`mt-12 mb-8 ${align ? 'text-center flex flex-col items-center' : ''}`}>
                        <h1 className='text-5xl sm:text-6xl font-bold text-[#222222] mb-6'>{title}</h1>
                        <p className={`text-xl sm:text-2xl text-[#555555] max-w-2xl ${align ? 'mx-auto' : ''}`}>{description}</p>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 w-full overflow-hidden mb-[-1px]'>
                <Curve />
            </div>
        </div>
    );
};

export default HeaderSectionPages;
