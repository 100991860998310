import { useState } from 'react';
import Header from '../components/Header';
import NewsLetter from '../components/NewsLetter';
import HeaderSectionPages from '../components/HeaderSectionPages';
import InfoWrapper from '../components/InfoWrapper';

const FAQ = () => {
    const data = {
        Profil: [
            {
                title: 'Kako se registrirati i povezati račune?',
                description:
                    'Registracija je brza i jednostavna. Korisnik treba unijeti osnovne podatke: ime, prezime,e-mail adresu, lozinku i billing adresu (adresu na koju prima račune za režije). Nakon unosa, sustav automatski prepoznaje izdavatelje povezane s tom adresom i prikazuje račune u korisničkom profilu.',
            },
            {
                title: 'Mogu li promijeniti svoje podatke nakon registracije?',
                description: 'Da, korisnici mogu ažurirati svoje osobne podatke u postavkama profila.',
            },
            {
                title: 'Da li moguće imati više billing adresa na jednom računu?',
                description: 'Da, korisnici mogu dodati više billing adresa i pratiti račune sa svih lokacija.',
            },
            {
                title: 'Kako mogu deaktivirati ili izbrisati svoj račun?',
                description: 'Korisnici mogu podnijeti zahtjev za brisanje računa putem korisničke podrške.',
            },
            {
                title: 'Postoji li verifikacija računa prilikom registracije?',
                description: 'Da, korisnici trebaju potvrditi svoju e-mail adresu kako bi aktivirali račun.',
            },
            {
                title: 'Kako mogu promijeniti način primanja obavijesti?',
                description: 'Korisnici mogu odabrati primanje obavijesti putem e-maila ili push notifikacija.',
            },
            {
                title: 'Mogu li koristiti svoj račun na više uređaja?',
                description: 'Da, račun je dostupan na različitim uređajima, a podaci su sinkronizirani.',
            },
            {
                title: 'Da li moguće imati više billing adresa na jednom računu?',
                description: 'Da, korisnici mogu dodati više billing adresa i pratiti račune sa svih lokacija.',
            },
        ],
        Račun: [
            {
                title: 'Trebam li ručno dodavati račune ili unositi podatke o izdavateljima?',
                description:
                    'Ne, sustav sve radi automatski i prikazuje ih u vašem profilu. Korisnik ne mora unositi nikakve dodatne podatke, a svi računi se ažuriraju u realnom vremenu.',
            },
            {
                title: 'Kako se moji računi učitavaju u sustav?',
                description:
                    'Nakon registracije, svi vaši računi od prepoznatih izdavatelja automatski će biti prikazani u vašem profilu. Računi su uvijek ažurirani i dostupni na jednom mjestu, bez potrebe za fizičkom dokumentacijom.',
            },
            {
                title: 'Mogu li dodati račune koje sustav nije prepoznao?',
                description:
                    'Trenutno nije potrebno ručno dodavanje računa. Ako neki izdavatelj još nije integriran, sustav će vas o tome obavijestiti i raditi na dodavanju podrške za njega.',
            },
            {
                title: 'Kako mogu filtrirati račune?',
                description: 'Možete koristiti napredne filtere prema iznosu, datumu dospijeća ili izdavatelju računa.',
            },
            {
                title: 'Mogu li preuzeti račune u PDF formatu?',
                description: 'Da, svi računi su dostupni za preuzimanje u PDF formatu unutar korisničkog profila.',
            },
            {
                title: 'Kako mogu pratiti potrošnju kroz račune?',
                description: 'Korisnici mogu analizirati potrošnju kroz vremenske periode pomoću naprednih izvještaja u aplikaciji.',
            },
            {
                title: 'Kako mogu promijeniti način plaćanja računa?',
                description: 'Moguće je dodati i promijeniti načine plaćanja u korisničkim postavkama.',
            },
            {
                title: 'Kako mogu znati kada mi stiže novi račun?',
                description: 'Platforma automatski obavještava korisnike kada stigne novi račun putem e-maila ili push notifikacija.',
            },
            {
                title: 'Postoji li mogućnost podjele računa među više korisnika?',
                description: 'Da, korisnici mogu dijeliti račun s cimerima ili članovima obitelji i definirati postotke plaćanja.',
            },
            {
                title: 'Kako mogu preusmjeriti račun na drugog korisnika?',
                description: 'Sustav omogućuje preusmjeravanje računa na drugog korisnika privremeno ili trajno.',
            },
        ],
        'Naše fore': [
            {
                title: 'Kako mogu platiti svoje račune putem sustava?',
                description:
                    'Plaćanje je brzo, sigurno i pouzdano. Korisnik jednostavno odabire račun koji želi platiti, unosi podatke o kartici kojom želi platiti i potvrđuje transakciju. Sve se obavlja u nekoliko klikova, bez potrebe za odlaskom u banku ili korištenjem dodatnih aplikacija.',
            },
            {
                title: 'Koje metode plaćanja su podržane?',
                description:
                    'Podržano je plaćanje svim VISA, Mastercard, Maestro, Amex i Diners karticama, plaćanje na rate, elektronsko i mobilno bankarstvo te QR code plaćanja (Kviko, OPA, itd.).',
            },
            {
                title: 'Što ako zaboravim platiti račun na vrijeme?',
                description:
                    'Sustav vas automatski obavještava o dospjelim računima i šalje podsjetnike kako biste izbjegli kašnjenja i dodatne troškove prouzrokovane kašnjenjem sa uplatama.',
            },
            {
                title: 'Mogu li postaviti automatsko plaćanje?',
                description:
                    'Da, korisnici mogu omogućiti automatsko plaćanje odabranih računa kako bi osigurali pravovremene uplate bez ručnog unosa podataka.',
            },
            {
                title: 'Kako mogu znati da je plaćanje uspješno?',
                description:
                    'Nakon uspješnog plaćanja, korisnici dobivaju potvrdu putem e-maila, a račun se automatski označava kao plaćen u sustavu.',
            },
            {
                title: 'Mogu li koristiti više načina plaćanja?',
                description: 'Da, moguće je registrirati više kartica i bankovnih računa te birati željeni način plaćanja za svaki račun.',
            },
            {
                title: 'Postoji li naknada za plaćanje računa?',
                description:
                    'Sustav ne naplaćuje dodatne naknade za plaćanje računa, ali određene banke ili izdavatelji kartica mogu naplatiti proviziju ovisno o vrsti transakcije.',
            },
            {
                title: 'Kako mogu pratiti status mojih plaćanja?',
                description:
                    'Status svih transakcija dostupan je unutar korisničkog profila, s mogućnošću filtriranja prema datumu, iznosu i izdavatelju računa.',
            },
            {
                title: 'Mogu li poništiti plaćanje ako sam pogriješio?',
                description:
                    'Ako je transakcija već obrađena, potrebno je kontaktirati izdavatelja računa za povrat sredstava. U nekim slučajevima moguće je poništavanje unutar određenog vremenskog okvira.',
            },
            {
                title: 'Kako mogu dobiti račun za obavljenu uplatu?',
                description:
                    'Svi računi za obavljena plaćanja dostupni su unutar sustava i mogu se preuzeti u PDF formatu ili poslati na e-mail korisnika.',
            },
        ],
        'Izdavatelji računa': [
            {
                title: 'Kako mogu postati partner izdavatelj računa?',
                description:
                    'Izdavatelji računa mogu se prijaviti za suradnju putem naše web stranice ili kontaktirati naš tim putem e-maila. Proces uključuje registraciju, verifikaciju i tehničku integraciju.',
            },
            {
                title: 'Koje su prednosti digitalne distribucije računa?',
                description:
                    'Digitalna distribucija računa omogućuje smanjenje troškova ispisa i dostave, brže procesuiranje uplata te poboljšano korisničko iskustvo kroz automatizirane obavijesti i praćenje plaćanja.',
            },
            {
                title: 'Kako mogu pratiti status poslanih računa?',
                description:
                    'Izdavatelji imaju pristup nadzornoj ploči gdje mogu pregledati status poslanih računa, uključujući isporučene, otvorene i plaćene račune u realnom vremenu.',
            },
            {
                title: 'Postoji li mogućnost prilagodbe izgleda računa?',
                description:
                    'Da, izdavatelji mogu prilagoditi izgled svojih računa uključujući logo, boje i izgled fakture kako bi odgovarali njihovom brendu.',
            },
            {
                title: 'Kako se računi integriraju s našim sustavom?',
                description:
                    'Integracija se vrši putem API-ja koji omogućuje automatsko slanje i praćenje računa unutar platforme Račun.hr.',
            },
            {
                title: 'Kako se osigurava sigurnost podataka izdavatelja?',
                description:
                    'Koristimo najsuvremenije metode enkripcije i sigurnosne protokole kako bismo zaštitili podatke svih korisnika i izdavatelja računa.',
            },
            {
                title: 'Mogu li izdavatelji pratiti neplaćene račune?',
                description:
                    'Da, sustav omogućuje pregled neplaćenih računa, slanje automatskih podsjetnika i generiranje izvještaja o kašnjenju plaćanja.',
            },
            {
                title: 'Postoji li podrška za masovno slanje računa?',
                description:
                    'Da, platforma omogućuje masovno slanje računa velikom broju korisnika u jednom koraku, optimizirajući proces naplate.',
            },
            {
                title: 'Kako izdavatelji mogu poboljšati naplatu računa putem vaše platforme?',
                description:
                    'Korištenjem naših alata za automatizaciju naplate, izdavatelji mogu smanjiti kašnjenja u plaćanju, omogućiti fleksibilne metode plaćanja i poboljšati komunikaciju s korisnicima.',
            },
            {
                title: 'Koje vrste poslovnih subjekata mogu postati izdavatelji?',
                description:
                    'Naša platforma podržava sve vrste izdavatelja računa, uključujući komunalna poduzeća, telekom operatere, osiguravajuće kuće i pružatelje drugih usluga.',
            },
        ],
        'Dodatne pogodnosti': [
            {
                title: 'Kako mogu koristiti aplikaciju na mobilnom telefonu?',
                description:
                    'Naša mobilna aplikacija omogućuje jednostavno plaćanje računa kroz dva koraka: odaberite račune za plaćanje i odaberite način plaćanja. Također omogućava primanje obavijesti o novim računima.',
            },
            {
                title: 'Koje su prednosti elektronske distribucije računa?',
                description:
                    'Korisnici ne moraju čuvati papirne račune, štede prostor i doprinosi se zaštiti okoliša. Računi su dostupni 24/7, bez čekanja u redovima.',
            },
            {
                title: 'Postoji li napredna statistika potrošnje?',
                description:
                    'Da, korisnici mogu usporediti račune za različita razdoblja, analizirati potrošnju i planirati buduće troškove.',
            },
            {
                title: 'Kako mogu postaviti podsjetnike za plaćanje?',
                description: 'Korisnici mogu omogućiti push notifikacije i email podsjetnike za dospjele račune.',
            },
            {
                title: 'Je li moguće koristiti aplikaciju na više uređaja?',
                description: 'Da, račun se može koristiti na više uređaja, a podaci su sinkronizirani u realnom vremenu.',
            },
            {
                title: 'Mogu li koristiti aplikaciju u inozemstvu?',
                description: 'Da, aplikacija je dostupna korisnicima širom svijeta, uključujući dijasporu.',
            },
        ],
    };

    const [selectedCategory, setSelectedCategory] = useState('Profil');

    const categories = Object.keys(data);

    return (
        <>
            <div className='absolute w-full top-0 left-0 z-10'>
                <Header />
            </div>

            <HeaderSectionPages
                description='Imate pitanja? Ovdje su odgovori na najčešće postavljana pitanja!'
                title='Često postavljana pitanja'
                align
            />

            <div className='flex sm:flex-row flex-col justify-center gap-10 md:gap-16 lg:gap-20 py-10 md:py-16 lg:py-20 px-4 md:px-6'>
                <div
                    className='sm:w-[285px] sm:max-w-[285px] min-w-[285px] flex flex-col gap-4 font-medium text-[#696771] 
                    border border-[#EAE9F2] px-8 py-4 justify-center h-[285px] font-md align-start rounded-lg bg-[#F7F7FB] w-full xl:mt-0 mt-[40px]'
                >
                    {categories.map((category) => (
                        <span
                            key={category}
                            className={`cursor-pointer py-1 ${
                                selectedCategory === category ? 'text-[#473bf0] font-semibold' : 'hover:text-[#473bf0]'
                            }`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </span>
                    ))}
                </div>

                <div className='flex flex-col gap-4 justify-start items-start w-full max-w-lg'>
                    {data[selectedCategory].map((item, index) => (
                        <InfoWrapper
                            key={index}
                            title={item.title}
                            description={item.description}
                            titleColor='text-[#19191B]'
                            descriptionColor='text-[#696871] font-circular'
                            textAlign='left'
                            small
                        />
                    ))}
                </div>
            </div>
            <span className='flex flex-row justify-center py-4 gap-2 font-roboto text-[16px] leading-[24px] text-[#696871] font-light'>
                Niste našli odgovor?
                <a className='text-[#ff2c25] hover:underline' href='/contact'>
                    Kontaktirajte nas!
                </a>
            </span>

            <NewsLetter />
        </>
    );
};

export default FAQ;
