export const MobileAppCards = ({ description, title, icon: Icon, bgColor, bgReminder, titleColor, descriptionColor }) => {
    return (
        <div className={`h-[95px] rounded-[10px] ${bgReminder} p-2`}>
            <div className='flex flex-row gap-4'>
                <div className='pt-3 pl-2'>
                    <div className={`w-[55px] h-[55px] ${bgColor} bg-opacity-10 rounded-full flex items-center justify-center mb-4`}>
                        {Icon && <Icon className='w-[22px] h-[22px]' />}
                    </div>
                </div>

                <div className='flex flex-col justify-center flex-col p-2'>
                    <h1 className={`text-[21px] leading-[24.61px] font-bold tracking-[-0.66px] font-roboto  ${descriptionColor}`}>
                        {description}
                    </h1>
                    <p className={`text-[21px] leading-[24.61px]  tracking-[-0.66px] font-roboto font-thin ${titleColor}`}>{title}</p>
                </div>
            </div>
        </div>
    );
};

export default MobileAppCards;
