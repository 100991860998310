import { Curve } from '../icons/Icons';

const ContactUsSection = () => {
    return (
        <div className='relative bg-red-400'>
            <div
                style={{
                    background: 'linear-gradient(to right, #EFF2FE 0%, white 100%)',
                }}
                className='min-h-[430px] w-full pt-28 pb-16 px-4 sm:px-12 sm:min-h-[550px] '
            >
                <div className='max-w-5xl mx-auto'>
                    <div className='mt-12 mb-8'>
                        <h1 className='text-5xl sm:text-6xl font-bold text-[#222222] mb-6'>Kontaktiraj nas</h1>
                        <p className='text-xl sm:text-2xl text-[#555555] max-w-2xl'>
                            Imate pitanja ili želite postati naš partner? 
                            Kontaktirajte nas – uvijek smo tu da vam pomognemo!
                        </p>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 w-full overflow-hidden mb-[-1px]'>
                <Curve />
            </div>
        </div>
    );
};

export default ContactUsSection;
