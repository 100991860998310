const Card = ({ title, text, icon: Icon, bgColor }) => {
    return (
        <div className='w-full sm:w-[255px] h-[274px] left-[530px] bg-white rounded-[10px] flex flex-col items-start py-4 px-6'>
            <div className={`w-[69px] h-[69px] ${bgColor} bg-opacity-10 rounded-full flex items-center justify-center mb-4 `}>
                {Icon && <Icon className='w-[24px] h-[28px]' />}
            </div>
            <div className='text-[#19191B] font-roboto font-bold text-[24px] leading-[28px] tracking-[-0.75px]'>{title}</div>
            <p className='text-[#696871] font-roboto font-light text-[16px] leading-[28px] tracking-[-0.5px] mt-[14px]'>{text}</p>
        </div>
    );
};

export default Card;
