import { useState, useEffect } from 'react';
import { BurgerMenu } from '../icons/Icons';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 640) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='relative'>
            <div className='flex flex-row items-center bg-transparent gap-14 pt-4 z-10 w-full'>
                <div className='hidden sm:flex'>
                    <div className='flex flex-row justify-center gap-8 items-center px-12'>
                        <div className='cursor-pointer w-[208px]'>
                            <Link to={'/'}>
                                <img src='./images/RacunHrLogo1.svg' alt='Logo' />
                            </Link>
                        </div>
                        <Link to={'/about-us'}>
                            <p className='font-roboto font-extralight text-md text-black cursor-pointer hover:text-[#F04037]'>O nama</p>
                        </Link>
                        <Link to={'/contact'}>
                            <p className='font-roboto font-extralight text-md text-black cursor-pointer hover:text-[#F04037]'>Kontakt</p>
                        </Link>
                        <Link to={'/faq'}>
                            <p className='font-roboto font-extralight text-md text-black cursor-pointer hover:text-[#F04037]'>FAQ</p>
                        </Link>
                    </div>
                </div>
                <div className='flex flex-row sm:hidden w-full justify-between items-center px-4 pt-2'>
                    <Link to={'/'}>
                        <div className='cursor-pointer w-[208px]'>
                            <img src='./images/RacunHrLogo1.svg' alt='Logo' />
                        </div>
                    </Link>
                    <button onClick={toggleMenu} className='text-black focus:outline-none'>
                        {isMenuOpen ? 'X' : <BurgerMenu className='h-6 w-6' />}
                    </button>
                </div>
            </div>

            <div
                className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
                    isMenuOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <div className='flex justify-end p-8'>
                    <button onClick={toggleMenu} className='text-black focus:outline-none text-xl'>
                        X
                    </button>
                </div>
                <nav className='flex flex-col gap-12 pt-4 px-4 text-xl' aria-label='Main Navigation'>
                    <Link to={'/about-us'}>
                        <p
                            className={`font-roboto font-extralight text-md md:text-lg cursor-pointer transition-colors duration-300 ${
                                location.pathname === '/about-us' ? 'text-[#F04037]' : 'text-black hover:text-[#F04037]'
                            }`}
                        >
                            O nama
                        </p>
                    </Link>
                    <Link to={'/contact'}>
                        <p
                            className={`font-roboto font-extralight text-md md:text-lg cursor-pointer transition-colors duration-300 ${
                                location.pathname === '/contact' ? 'text-[#F04037]' : 'text-black hover:text-[#F04037]'
                            }`}
                        >
                            Kontakt
                        </p>
                    </Link>
                    <Link to={'/faq'}>
                        <p
                            className={`font-roboto font-extralight text-md md:text-lg cursor-pointer transition-colors duration-300 ${
                                location.pathname === '/faq' ? 'text-[#F04037]' : 'text-black hover:text-[#F04037]'
                            }`}
                        >
                            FAQ
                        </p>
                    </Link>
                </nav>
            </div>

            {isMenuOpen && <div className='fixed inset-0 bg-black bg-opacity-50 z-40' onClick={toggleMenu}></div>}
        </div>
    );
};

export default Header;
