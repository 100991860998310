import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { ArrowLeft, ArrowRight } from '../icons/Icons';

const sliderData = [
    {
        chapter: '1. Zaprimanje računa',
        title: 'Računi stižu automatski',
        description:
            'Račun.hr automatski prepoznaje i preuzima vaše račune od izdavatelja – sve imate na jednom mjestu, bez ručnog unosa i papira.',
        image: '/images/rhr-slider1.svg',
    },
    {
        chapter: 'Pregled i kontrola',
        title: 'Pratite potrošnju i čuvajte račune',
        description: 'Svi plaćeni računi su arhivirani, a analitika troškova vam daje potpuni uvid u mjesečne i godišnje izdatke.',
        image: '/images/rhr-slider2.svg',
    },
    {
        chapter: 'Podsjetnici i plaćanje',
        title: 'Nikad više ne kasnite s plaćanjem',
        description:
            'Dobivate pametne podsjetnike prije roka dospijeća i plaćate online u par klikova – brzo, sigurno i kada vama odgovara.',
        image: '/images/rhr-slider3.svg',
    },
];

const Slider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const prevRef = React.useRef(null);
    const nextRef = React.useRef(null);

    const [isMobile, setIsMobile] = useState(false);

    React.useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .mobile-card-swiper .swiper-pagination {
                bottom: 20px !important;
            }
            .mobile-card-swiper .swiper-pagination-bullet {
                background: #3201fe
            
            }
            .mobile-card-swiper .swiper-pagination-bullet-active {
                background: #3201fe
          
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <div className='w-full flex justify-center sm:px-6 md:px-10'>
            <div className='relative w-full max-w-[1100px]'>
                <button
                    ref={prevRef}
                    className='hidden md:flex absolute top-1/2 -translate-y-1/2 left-[-30px] z-30 w-[65px] h-[65px] rounded-full bg-white items-center justify-center focus:outline-none shadow-md'
                    aria-label='Previous slide'
                >
                    <ArrowLeft />
                </button>
                <button
                    ref={nextRef}
                    className='hidden md:flex absolute top-1/2 -translate-y-1/2 right-[-30px] z-30 w-[65px] h-[65px] rounded-full bg-indigo-600 items-center justify-center focus:outline-none shadow-md'
                    aria-label='Next slide'
                >
                    <ArrowRight />
                </button>

                <div className='bg-white rounded-lg shadow-none sm:shadow-2xl h-auto md:h-[390px] flex items-center'>
                    <div className='relative w-full'>
                        {isMobile ? (
                            <Swiper
                                modules={[Pagination, EffectCards, Navigation]}
                                effect={'cards'}
                                grabCursor={true}
                                pagination={{
                                    clickable: true,
                                    dynamicMainBullets: true,
                                }}
                                loop={true}
                                onSlideChange={handleSlideChange}
                                className='pb-12 mobile-card-swiper'
                            >
                                {sliderData.map((slide, index) => (
                                    <SwiperSlide
                                        key={index}
                                        className='card-slide'
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '400px' }}
                                    >
                                        <div
                                            className='flex flex-col items-center gap-4 p-6 h-[380px] w-full overflow-hidden bg-white rounded-lg shadow-xl transform transition-all'
                                            style={{
                                                boxShadow:
                                                    '0 15px 35px -5px rgba(0, 0, 0, 0.15), 0 10px 15px -5px rgba(0, 0, 0, 0.1), 0 20px 20px -10px rgba(0, 0, 0, 0.25), 0 -12px 18px -8px rgba(0, 0, 0, 0.2), 0 -5px 15px -5px rgba(255, 255, 255, 0.9)',
                                                transform: 'perspective(1200px) rotateX(3deg) scale(0.98)',
                                            }}
                                        >
                                            <div className='w-[150px] h-[150px] rounded flex items-center justify-center flex-shrink-0 overflow-hidden'>
                                                <img src={slide.image} alt={`Chapter ${index + 1}`} className='w-full h-full' />
                                            </div>

                                            <div className='flex-1 min-w-0 text-center'>
                                                <div className='text-indigo-600 text-sm mb-2'>{slide.chapter}</div>
                                                <h2 className='text-xl font-bold mb-3'>{slide.title}</h2>
                                                <p className='text-sm text-gray-600 leading-relaxed'>{slide.description}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={20}
                                slidesPerView={1}
                                loop={true}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                pagination={false}
                                onBeforeInit={(swiper) => {
                                    if (swiper.params.navigation) {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                    }
                                }}
                                onSlideChange={handleSlideChange}
                                className='pb-12 md:pb-10'
                            >
                                {sliderData.map((slide, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='flex flex-row items-center justify-center gap-14 xl:gap-20 p-6 h-full w-full xl:w-[780px] mx-auto overflow-hidden'>
                                            <div className='w-[318px] h-[291px] rounded flex items-center justify-center flex-shrink-0 overflow-hidden relative'>
                                                <img src={slide.image} alt={`Chapter ${index + 1}`} className='w-full h-full' />
                                            </div>

                                            <div className='flex-1 min-w-0 text-left'>
                                                <div className='text-indigo-600 text-sm mb-2'>{slide.chapter}</div>
                                                <h2 className='text-2xl font-bold mb-4'>{slide.title}</h2>
                                                <p className='text-base text-gray-600 leading-relaxed'>{slide.description}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;
