const InfoWrapper = ({ title, description, titleColor, descriptionColor, textAlign = 'left', titlePadding, small, children }) => {
    return (
        <div className='w-full px-4 sm:px-6 lg:px-0 mx-auto lg:mx-0 max-w-xl sm:pt-[40px] pt-[40px] xl:pt-0'>
            <div className={`flex flex-col ${small ? 'gap-2' : 'gap-6'} `}>
                <h1
                    className={`font-roboto font-bold ${
                        small
                            ? 'text-[24px] leading-[39px]'
                            : 'text-[2rem] sm:text-[2.5rem] xl:text-[54px] leading-[1.2] xl:leading-[70px] tracking-[-1.88px]'
                    } ${titleColor} text-${textAlign} ${titlePadding ? 'pb-0 xl:pb-[100px]' : ''} `}
                >
                    {title}
                </h1>

                <p
                    className={`font-roboto ${
                        small
                            ? 'text-[16px] leading-[24px]'
                            : 'text-[18px] sm:text-[21px] leading-[1.6] sm:leading-[39px] tracking-[-0.66px]'
                    } ${descriptionColor} font-thin`}
                >
                    {description}
                </p>
                {children}
            </div>
        </div>
    );
};
export default InfoWrapper;
