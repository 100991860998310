import { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Clients from '../components/Clients';
import PayTrack from '../components/PayTrack';
import NewsLetter from '../components/NewsLetter';
import { Reminder } from '../components/Reminder';
import { Bell, Curve2 } from '../icons/Icons';
import MobileApp from '../components/MobileApp';
import InfoWrapper from '../components/InfoWrapper';
import Button from '../components/Button';
import Slider from '../components/Slider';
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection';

const Home = () => {
    const infoWrapperRef = useRef(null);
    const reminderMobileRef = useRef(null);
    const reminderDesktopRef = useRef(null);
    const redReminderDesktopRef = useRef(null);
    const redReminderMobileRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                    // Disable animations on mobile if they're causing layout issues
                    // disable: window.innerWidth < 768,
                    //optional
                });
            },
            { threshold: 0.3 }
        );

        if (infoWrapperRef.current) observer.observe(infoWrapperRef.current);
        if (reminderMobileRef.current) observer.observe(reminderMobileRef.current);
        if (reminderDesktopRef.current) observer.observe(reminderDesktopRef.current);
        if (redReminderDesktopRef.current) observer.observe(redReminderDesktopRef.current);
        if (redReminderMobileRef.current) observer.observe(redReminderMobileRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div className='flex flex-col w-full'>
            {/* <div className='relative'>
                <div className='relative'>
                    <img src='./images/Background.jpeg' alt='bgImage' className='w-full h-[80%] object-cover hidden md:block pl-32' />
                    <div className='w-full h-[80vh] py-4 sm:h-[650px] md:h-[450px] sm:py-0 md:hidden md:bg-transparent relative'>
                        <img
                            src='./images/Background.jpeg'
                            alt='bgImage'
                            className='w-full h-full object-cover absolute top-0 left-0 md:hidden max-h-[100vh] min-h-[590px]'
                        />
                    </div>
                </div>
                <div className='absolute inset-0'>
                    <HeroInfo />
                </div>
            </div> */}
            <HeroSection />
            <Clients />
            <div id='paytrack'>
                <PayTrack />
            </div>

            <div>
                <div className='min-h-[200px] w-full py-[28px] px-4 sm:px-12' id='slider'>
                    <div className='max-w-5xl mx-auto'>
                        <div className='mt-12 mb-8  text-center flex flex-col items-center'>
                            <h1 className='text-5xl sm:text-6xl font-bold text-[#222222] mb-6'>Kako radi Račun.hr Platforma?</h1>
                            <p className='text-xl sm:text-2xl text-[#555555] max-w-2xl mx-auto'>
                                Od zaprimanja računa do analize potrošnje – sve ide automatski, a vi imate potpunu kontrolu.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Slider />

            <div className='relative flex xl:flex-row flex-col justify-start xl:justify-center xl:gap-[360px] items-center h-[700px] sm:h-[640px] xl:pt-20 pt-8'>
                <div ref={infoWrapperRef} data-aos='fade-right' className='transition-transform duration-1000'>
                    <InfoWrapper
                        title='Račun.hr Dashboard'
                        description='Račun.hr dashboard je vaš centralni pregled svih računa, uplata i troškova. Na jednom mjestu pratite što je plaćeno, što dospijeva, kolika je ukupna mjesečna potrošnja i koliko ste uštedjeli.'
                        descriptionColor='text-[#696871]'
                        titleColor='text-[#19191]'
                        titlePadding
                    >
                        <div className='w-[216px] h-[66px]'>
                            <Link to='/register'>
                                <Button>Prijavi se</Button>
                            </Link>
                        </div>
                    </InfoWrapper>
                </div>
                <div
                    className='xl:hidden block pt-8 transition-opacity duration-1000 opacity-0'
                    ref={reminderMobileRef}
                    data-aos='fade-in'
                    data-aos-delay='500'
                >
                    <Reminder
                        icon={Bell}
                        title={'Vaš račun je sada dostupan.'}
                        description={'Novi račun za električnu energiju!'}
                        bgColor='bg-[#5454D4] shadow-[0px_32px_34px_0px_#1C1B2033]'
                        bgReminder='bg-[#FEDC5A]'
                        titleColor='text-black'
                        descriptionColor='text-black'
                    />
                </div>

                <div className='relative w-[370px]'>
                    <div
                        className='absolute -left-[240px] top-[280px] z-10 xl:block hidden transition-opacity duration-1000 opacity-0'
                        ref={reminderDesktopRef}
                        data-aos='fade-in'
                        data-aos-delay='500'
                    >
                        <Reminder
                            icon={Bell}
                            title={'Vaš račun je sada dostupan.'}
                            description={'Novi račun za električnu energiju!'}
                            bgColor='bg-[#5454D4] shadow-[0px_32px_34px_0px_#1C1B2033]'
                            bgReminder='bg-[#FEDC5A]'
                            titleColor='text-black'
                            descriptionColor='text-black'
                        />
                    </div>
                    <div className='h-[566px] hidden xl:block '>
                        <img src='./images/InfoImage1.png' alt='InfoImage1' className='w-full h-full object-cover' />
                    </div>
                </div>
            </div>

            <div className='relative flex xl:flex-row flex-col justify-start xl:justify-center xl:gap-[60px] items-center h-[790px] sm:h-[840px] xl:pt-0 pt-8 bg-[#F7F7FB]'>
                <div className='absolute top-0 left-[-200px] w-full overflow-hidden mt-[-1px]'>
                    <Curve2 />
                </div>
                <div className='xl:pt-[60px] flex xl:flex-row flex-col justify-start xl:justify-center xl:gap-[260px] items-center h-[600px] sm:h-[640px]'>
                    <div className='h-[566px] hidden xl:block relative '>
                        <div
                            className='absolute top-[140px] right-[145px] transition-opacity duration-1000 opacity-0'
                            ref={redReminderDesktopRef}
                            data-aos='fade-in'
                            data-aos-delay='500'
                        >
                            <Reminder
                                icon={Bell}
                                title={'Rok za plaćanje: 29.01.2025.'}
                                description={'Rok plaćanja računa je prošao!'}
                                bgColor='bg-[#5454D4] shadow-[0px_32px_34px_0px_#1C1B2033]'
                                bgReminder='bg-[#F04037]'
                                titleColor='text-black'
                                descriptionColor='text-black'
                            />
                        </div>
                        <img src='./images/InfoImage2.png' alt='InfoImage2' className='w-full h-full object-cover mt-[45px]' />
                    </div>

                    <MobileApp />

                    <div
                        className='xl:hidden block pt-8 transition-opacity duration-1000 opacity-0'
                        ref={redReminderMobileRef}
                        data-aos='fade-in'
                        data-aos-delay='600'
                    >
                        <Reminder
                            icon={Bell}
                            title={'Rok za plaćanje: 29.01.2025.'}
                            description={'Rok plaćanja računa je prošao!'}
                            bgColor='bg-[#5454D4] shadow-[0px_32px_34px_0px_#1C1B2033]'
                            bgReminder='bg-[#F04037]'
                            titleColor='text-black'
                            descriptionColor='text-black'
                        />
                    </div>
                </div>
            </div>

            <NewsLetter />
        </div>
    );
};

export default Home;
