import AOS from 'aos';
import Card from './Card';
import { Curve, OnlinePlacanje, DijeljenjeTroskova, Kalendar, Podsjetnici } from '../icons/Icons';
import InfoWrapper from './InfoWrapper';
import Button from './Button';
import 'aos/dist/aos.css';
import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PayTrack = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const cardData = [
        {
            title: 'Online plaćanje',
            text: 'Brzo, sigruno i jednostavno - platite svoje račune online putem Račun.hr !',
            icon: OnlinePlacanje,
            bgColor: 'bg-red-500',
        },
        {
            title: 'Dijeljenje troškova',
            text: 'Trošak procesiranja dijelimo između korisnika i izdavatelja račun',
            icon: DijeljenjeTroskova,
            bgColor: 'bg-yellow-400',
        },
        {
            title: 'Podsjetnici',
            text: 'Pametni podsjetnici koji vas pravovremeno obavještavaju o pristiglim računima i rokovima plaćanja.',
            icon: Podsjetnici,
            bgColor: 'bg-blue-600',
        },
        {
            title: 'Kalendar plaćanja',
            text: 'Planirajte svoje troškove unaprijed i budite uvijek u toku s rokovima.',
            icon: Kalendar,
            bgColor: 'bg-indigo-500',
        },
    ];

    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    const navigateToFunctionalities = () => {
        if (location.pathname === '/about-us') {
            scrollToSection('functionalities');
        } else {
            navigate('/about-us', { state: { scrollToId: 'functionalities' } });
        }
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='bg-[#3201fe] h-[1700px] sm:h-[1000px] flex flex-col lg:flex-row items-center justify-center p-6 gap-12 relative'>
            <div ref={imgRef} className='absolute top-0 right-0 overflow-hidden z-[1] transition-transform duration-1000'>
                <img src='./images/l1-feature-oval.png' alt='oval' data-aos='fade-left' />
            </div>
            <div className='w-full max-w-[550px] grid grid-cols-1 sm:grid-cols-2 gap-6 order-2 lg:order-1'>
                {cardData.map((item, index) => (
                    <Card key={index} title={item.title} text={item.text} icon={item.icon} bgColor={item.bgColor} />
                ))}
            </div>

            <div className='w-full sm:max-w-[550px] flex flex-col order-1 lg:order-2 relative z-10 xl:pr-[100px]'>
                <InfoWrapper
                    title='Naše najbolje fore za plaćanje računa'
                    description='Račun.hr vam na jednom mjestu omogućuje brzo i sigurno online plaćanje, pametne podsjetnike, pregledan kalendar, digitalnu arhivu računa i detaljan uvid u potrošnju - za jednostavno upravljanje obavezama i potpunu kontrolu nad finansijama.'
                    descriptionColor='text-white'
                    titleColor='text-white'
                    titlePadding
                >
                    <div className='w-[216px] h-[66px]' onClick={navigateToFunctionalities}>
                        <Button variant='secondary'>Pogledaj više fora</Button>
                    </div>
                </InfoWrapper>
            </div>
            <div className='absolute bottom-0 left-0 w-full overflow-hidden mb-[-1px]'>
                <Curve />
            </div>
        </div>
    );
};

export default PayTrack;
