import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FacebookIcon, FooterLogo, GoogleIcon } from '../icons/Icons';
import { useEffect } from 'react';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const navigateAndScrollToSection = (id) => {
        if (location.pathname === '/') {
            scrollToSection(id);
        } else {
            navigate('/', { state: { scrollToId: id } });
        }
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (location.state && location.state.scrollToId) {
            const id = location.state.scrollToId;

            const timer = setTimeout(() => {
                scrollToSection(id);

                window.history.replaceState({}, document.title);
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [location]);

    return (
        <div
            className='pt-20 w-full pb-0 mb-0 mt-20'
            style={{
                /*backgroundImage: "url('/images/ClientsBackground.png')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',*/
                backgroundColor: '#f0f0f0',
                position: 'relative',
            }}
        >
            <div className='flex flex-col sm:flex-row sm:gap-24 flex-wrap pb-4 items-center justify-center'>
                <div className='pb-8 sm:pb-0'>
                    <div onClick={scrollToTop} className='cursor-pointer'>
                        <FooterLogo />
                    </div>
                </div>
                <div className='flex flex-col text-left mb-6 sm:mb-0 sm:text-left text-center'>
                    <h1 className='pb-6 text-[16px] font-roboto font-bold text-black'>O nama</h1>
                    <div className='font-roboto text-[16px] font-extralight space-y-3 text-black text-opacity-80'>
                        <Link to='/about-us' onClick={() => window.scrollTo(0, 0)}>
                            <p>Naša misija</p>
                        </Link>

                        <p onClick={() => navigateAndScrollToSection('paytrack')} className='cursor-pointer'>
                            Naše fore
                        </p>
                    </div>
                </div>
                <div className='flex flex-col text-left mb-6 sm:mb-0 sm:text-left text-center'>
                    <h1 className='pb-6 text-[16px] font-roboto font-bold text-black'>Račun.hr</h1>
                    <div className='font-roboto text-[16px] font-extralight space-y-3 text-black text-opacity-80'>
                        <p onClick={() => navigateAndScrollToSection('slider')} className='cursor-pointer'>
                            Kako radi platforma?
                        </p>
                        <Link to='/faq' onClick={() => window.scrollTo(0, 0)}>
                            <p>FAQs</p>
                        </Link>
                    </div>
                </div>
                <div className='flex flex-col text-left mb-6 sm:mb-0 sm:text-left text-center'>
                    <div className='space-y-4'>
                        <div>
                            <img src='/images/Sponsor1.png' alt='Sponsor 1' className='w-20 h-20' />
                        </div>
                        <div>
                            <img src='/images/Sponsor2.png' alt='Sponsor 2' className='w-20 h-20' />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col text-left mb-6 sm:mb-0 sm:text-left text-center'>
                    <div className='space-y-4'>
                        <div>
                            <img src='/images/Sponsor3.png' alt='Sponsor 3' />
                        </div>
                        <div className='flex flex-row justify-center'>
                            <img src='/images/Sponsor4.png' alt='Sponsor 4' className='w-20 h-20' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='px-8 sm:px-16 md:px-24 lg:px-32 w-full'>
                <hr className='border-[#2F2F31] sm:mx-auto my-4' />

                <div className='sm:flex sm:items-center sm:justify-between pb-4'>
                    <span
                        className='font-roboto text-[13px] leading-[38px] tracking-[-0.41px] font-thin sm:text-center text-black'
                        style={{ opacity: 0.7 }}
                    >
                        © 2025 Račun hr, All Rights Reserved
                    </span>

                    {/* <div className='w-[85.33px] h-[16px] flex justify-between'>
                        <div className='cursor-pointer'>
                            <FacebookIcon />
                        </div>
                        <div className='cursor-pointer'>
                            <FacebookIcon />
                        </div>
                        <div className='cursor-pointer'>
                            <GoogleIcon />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Footer;
