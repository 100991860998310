export const Input = ({ placeholder, type, value, onChange, name, error, ...props }) => {
    return (
        <div className='mb-2 w-full'>
            <div className='h-[71px] w-full flex items-center bg-[#ffffff] rounded-lg p-1 w-[80%] sm:w-full border border-[#EAE9F2] relative'>
                <div className='pl-1 space-y-2'></div>
                <input
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={`flex-1 bg-transparent outline-none px-4 text-gray-700 ${error ? 'border-red-500' : ''}`}
                    {...props}
                />
            </div>
            {error && <div className='text-red-500 text-sm mt-1 pl-2'>{error}</div>}
        </div>
    );
};
