import Button from './Button';
import Header from './Header';
import { MailIcon } from '../icons/Icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const HeroSection = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/register', {
            state: { email },
        });
    };
    return (
        <div className='min-h-screen relative overflow-hidden'>
            <Header />
            <div className='container mx-auto px-4 py-12 md:py-20 lg:py-24 relative'>
                <div className='flex flex-col lg:flex-row items-center justify-between'>
                    <div className='w-full lg:w-1/2 lg:pr-8 mb-10 lg:mb-0 order-2 lg:order-1'>
                        <h1 className='font-roboto font-bold text-[40px]  xl:text-[60px] leading-[1.2] xl:leading-[70px] tracking-[-1.88px] text-gray-900 mb-6'>
                            Svi tvoji računi na jednom mjestu
                        </h1>
                        <p className='font-roboto text-[18px] sm:text-[21px] leading-[1.6] sm:leading-[39px] tracking-[-0.66px] text-gray-600 font-thin mb-8'>
                            Račun.hr je digitalna platforma za distribuciju i plaćanje režijskih računa u Hrvatskoj. Omogućava korisnicima
                            da primaju račune digitalno i plaćaju ih jednim klikom, bez čekanja u redu, bez papira i bez greške. Unesi email
                            i saznaj kad krećemo.
                        </p>
                        <div className='flex flex-col sm:flex-row items-center gap-4 sm:gap-6'>
                            <div className='h-[77px] w-full flex items-center bg-gray-100 rounded-lg p-1'>
                                <div className='p-4'>
                                    <MailIcon />
                                </div>
                                <input
                                    type='email'
                                    placeholder='Email adresa'
                                    className='flex-1 bg-transparent outline-none px-4 text-gray-700'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <div className='sm:w-[216px] w-full h-full hidden sm:block'>
                                    <form onSubmit={handleSubmit} className='w-full h-full'>
                                        <Button className='hvr-bounce-to-left custom-button hover:bg-[#C31A12]'>Pokreni revoluciju</Button>
                                    </form>
                                </div>
                            </div>
                            <div className='w-[216px] h-[66px] sm:hidden'>
                                <form onSubmit={handleSubmit} className='w-full h-full'>
                                    <Button>Pokreni revoluciju</Button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='w-full lg:w-1/2 relative order-1 lg:order-2 mb-10 lg:mb-0'>
                        <div className='relative flex justify-center'>
                            <div className='w-[330px] h-[677px] flex items-center justify-center text-gray-400'>
                                <img src='/images/HeroImage3.png' alt='hero_image' />
                            </div>

                            <div className='absolute top-10 md:top-20 -left-4 md:left-0 bg-indigo-600 text-white rounded-lg py-4 px-5 shadow-lg flex items-center w-64 md:w-72 animate-fade-in-down'>
                                <div className='bg-yellow-300 rounded-full p-2 flex items-center justify-center mr-3'>
                                    <svg
                                        className='w-4 h-4 text-white'
                                        fill='none'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='3'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                    >
                                        <path d='M5 13l4 4L19 7'></path>
                                    </svg>
                                </div>
                                <span className='text-base'>Digitalni računi. Brže, sigurnije, uvijek dostupno.</span>
                            </div>

                            <div className='absolute bottom-10 md:bottom-20 -right-4 md:right-0 bg-yellow-300 text-gray-900 rounded-lg py-4 px-5 shadow-lg flex items-center w-64 md:w-80 animate-fade-in-up'>
                                <div className='bg-indigo-600 rounded-full p-2 flex items-center justify-center mr-3'>
                                    <svg
                                        className='w-4 h-4 text-white'
                                        fill='none'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='3'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                    >
                                        <path d='M5 13l4 4L19 7'></path>
                                    </svg>
                                </div>
                                <span className='text-base'>Zaboravi na papire. Dobij i plati sve online.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
