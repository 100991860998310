import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import NoPathFound from './pages/NoPathFound';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Register from './pages/Register';
import SuccessPage from './pages/SuccessPage';

function App() {
    return (
        <Router>
            <div className='App'>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/success' element={<SuccessPage />} />
                    <Route path='*' element={<NoPathFound />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
