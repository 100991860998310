import { useState } from 'react';
import axios from 'axios';

/**
 * Custom hook for form validation and submission
 * @param {Object} initialFormData - Initial form data
 * @param {string} apiEndpoint - API endpoint for form submission
 * @param {Function} onSuccess - Callback function on successful submission
 * @returns {Object} Form state and handlers
 */
export const useFormValidation = (initialFormData, apiEndpoint, onSuccess) => {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        general: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
            }));
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            first_name: '',
            last_name: '',
            email: '',
            general: '',
        };

        if (!formData.first_name?.trim()) {
            newErrors.first_name = 'Ime je obavezno';
            valid = false;
        }

        if (!formData.last_name?.trim()) {
            newErrors.last_name = 'Prezime je obavezno';
            valid = false;
        }

        if (!formData.email?.trim()) {
            newErrors.email = 'Email adresa je obavezna';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Unesite važeću email adresu';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        setErrors((prev) => ({ ...prev, general: '' }));

        try {
            const response = await axios.post(apiEndpoint, formData);

            if ((response.data && response.data.status_code === 200) || response.status === 200) {
                if (onSuccess) {
                    onSuccess();
                }
            }
        } catch (err) {
            console.error('Form submission error:', err);

            if (err.response && err.response.data) {
                const responseData = err.response.data;

                if (responseData.validation_errors && responseData.validation_errors.length > 0) {
                    const newErrors = { ...errors };

                    responseData.validation_errors.forEach((validationError) => {
                        if (newErrors.hasOwnProperty(validationError.field)) {
                            newErrors[validationError.field] = validationError.error;
                        }
                    });

                    setErrors(newErrors);
                } else if (responseData.error) {
                    setErrors((prev) => ({ ...prev, general: responseData.error }));
                }
            } else {
                setErrors((prev) => ({
                    ...prev,
                    general: 'Došlo je do greške prilikom obrade forme. Molimo pokušajte ponovo.',
                }));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return {
        formData,
        setFormData,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
    };
};
