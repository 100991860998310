const Clients = () => {
    const clients = [
        { src: '/images/Client1.png', alt: 'Client 1' },
        { src: '/images/Client2.png', alt: 'Client 2' },
        { src: '/images/Client3.svg', alt: 'Client 3' },
        { src: '/images/Client4.svg', alt: 'Client 4' },
        { src: '/images/Client5.svg', alt: 'Client 5' },
    ];

    return (
        <div
            className='flex flex-wrap justify-center gap-8 py-8 px-12 md:justify-between bg-cover bg-center bg-no-repeat w-full'
            style={{ backgroundImage: "url('/images/ClientsBackground.png')", backgroundSize: 'cover' }}
        >
            {clients.map((client, index) => (
                <div key={index} className='flex justify-center items-center'>
                    <img src={client.src} alt={client.alt} className='max-w-[150px]' />
                </div>
            ))}
        </div>
    );
};

export default Clients;
