import { CheckMarkIcon } from '../icons/Icons';

const CheckMarkCard = ({ text }) => {
    return (
        <div className='flex flex-row h-[89px] w-[350px] border border-solid rounded-xl items-center px-4'>
            <div className='h-[30px] w-[30px] bg-[#5454D4] rounded-full flex items-center justify-center shrink-0'>
                <CheckMarkIcon />
            </div>

            <div className='w-4'></div>

            <div className='text-left text-[21px] leading-none flex-1'>{text}</div>
        </div>
    );
};

export default CheckMarkCard;
