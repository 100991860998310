import { useEffect, useRef } from 'react';
import Button from '../components/Button';
import Footer from '../components/Footer';
import InfoWrapper from '../components/InfoWrapper';
import { LikeIcon } from '../icons/Icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <Header />
            <div className='flex flex-col justify-center items-center gap-8 pt-8'>
                <div className='w-[118px] h-[118px] flex flex-row justify-center items-center bg-[#5454D4] rounded-full'>
                    <LikeIcon />
                </div>
                <div className='text-center flex flex-col items-center justify-center'>
                    <InfoWrapper
                        title='Hvala Vam!'
                        titleColor='text-[#000000]'
                        descriptionColor='text-[#000000]'
                        textAlign='center'
                        description='Vaš račun je uspješno kreiran. Obavijestićemo vas čim aplikacija postane dostupna, kako biste mogli jednostavno upravljati svojim računima na jednom mjestu.Pratite nas za novosti – uskoro stižemo.'
                    />
                </div>
                <div className='w-[216px] h-[66px]'>
                    <Link to='/'>
                        <Button>Pocetna!</Button>
                    </Link>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default SuccessPage;
