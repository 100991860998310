import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InfoWrapper from '../components/InfoWrapper';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Input } from '../components/Input';
import RegisterSection from '../components/RegisterSection';
import { REGISTER } from '../constants/api';
import { useFormValidation } from '../hooks/useFormValidation';

const Register = () => {
    const imgRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const initialFormData = {
        first_name: '',
        last_name: '',
        email: '',
    };

    const onSuccess = () => {
        navigate('/success', { replace: true });
        window.scrollTo(0, 0);
    };

    const { formData, setFormData, errors, isSubmitting, handleChange, handleSubmit } = useFormValidation(
        initialFormData,
        REGISTER,
        onSuccess
    );

    useEffect(() => {
        if (location.state) {
            const { email, name, surname } = location.state;

            setFormData((prevData) => ({
                ...prevData,
                first_name: name || '',
                last_name: surname || '',
                email: email || '',
            }));
        }
    }, [location, setFormData]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (imgRef.current) observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div>
            <div className='absolute w-full top-0 left-0 z-10'>
                <Header />
            </div>

            <RegisterSection />

            <div className='flex flex-col justify-center items-center py-12'>
                <InfoWrapper
                    title='Zajedno pokrećemo revoluciju'
                    titleColor='text-[#000000]'
                    descriptionColor='text-[#000000]'
                    textAlign='center'
                >
                    {errors.general && (
                        <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center'>
                            {errors.general}
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
                        <Input
                            type='text'
                            placeholder='Vaše Ime'
                            name='first_name'
                            value={formData.first_name}
                            onChange={handleChange}
                            error={errors.first_name}
                        />
                        <Input
                            type='text'
                            placeholder='Vaše prezime'
                            name='last_name'
                            value={formData.last_name}
                            onChange={handleChange}
                            error={errors.last_name}
                        />
                        <Input
                            type='email'
                            placeholder='Email adresa'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        <div className='h-[71px] w-full mt-2'>
                            <Button type='submit' disabled={isSubmitting}>
                                {isSubmitting ? 'Registracija u toku...' : 'Pokreni revoluciju'}
                            </Button>
                        </div>
                    </form>
                </InfoWrapper>
            </div>

            <Footer />
        </div>
    );
};

export default Register;
