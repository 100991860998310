import { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import InfoWrapper from './InfoWrapper';
import MobileAppCards from './MobileAppCards';
import { Mobile } from '../icons/Icons';
import { SmartPhone } from '../icons/Icons';

const MobileApp = () => {
    const infoWrapperRef = useRef(null);
    const cardOneRef = useRef(null);
    const cardTwoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                AOS.init({
                    duration: 1000,
                    once: false,
                });
            },
            { threshold: 0.3 }
        );

        if (infoWrapperRef.current) observer.observe(infoWrapperRef.current);
        if (cardOneRef.current) observer.observe(cardOneRef.current);
        if (cardTwoRef.current) observer.observe(cardTwoRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div className='flex flex-col justify-center items-center xl:min-h-screen xl:w-[50%] w-[100%]'>
            <div className='flex flex-row justify-center max-w-[500px]' ref={infoWrapperRef} data-aos='fade-right'>
                <InfoWrapper
                    title='Mobilna aplikacija'
                    description='Uz mobilnu aplikaciju Račun.hr, sve svoje račune nosite sa sobom. Primajte obavijesti o novim računima i rokovima plaćanja, plaćajte u par dodira i pratite svoju potrošnju u realnom vremenu – bilo kad i bilo gdje.'
                    descriptionColor='text-[#696871]'
                    titleColor='text-[ #19191]'
                />
            </div>

            <div className='mt-12 flex flex-col gap-4 self-start pl-4'>
                <div ref={cardOneRef} data-aos='fade-up' data-aos-delay='300'>
                    <MobileAppCards
                        icon={Mobile}
                        title={'Imate 5+ neplaćenih računa'}
                        description={'Platite ih sve na 1 klik'}
                        bgColor='bg-[#F04037] bg-opacity-10'
                        bgReminder='bg-[#FFFFFF]'
                        titleColor='text-[#696871]'
                        descriptionColor='text-[#19191B]'
                    />
                </div>

                <div ref={cardTwoRef} data-aos='fade-up' data-aos-delay='500'>
                    <MobileAppCards
                        icon={SmartPhone}
                        title={'Podijeli račun na obroke'}
                        description={'Plati na rate '}
                        bgColor='bg-[#5454D4] bg-opacity-10'
                        bgReminder='bg-[#FFFFFF]'
                        titleColor='text-[#696871]'
                        descriptionColor='text-[#19191B]'
                    />
                </div>
            </div>
        </div>
    );
};

export default MobileApp;
